const documentMask = (value) => {
  const cleanedValue = value.replace(/\D/g, '');

  if (cleanedValue?.length >= 12) {
    return cleanedValue.replace(
      /^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2}).*/,
      (match, group1, group2, group3, group4, group5) => {
        let maskedCpf = '';

        if (group1) {
          maskedCpf += group1;
          if (group1.length === 2) {
            maskedCpf += '.';
          }
        }

        if (group2) {
          maskedCpf += group2;
          if (group2.length === 3) {
            maskedCpf += '.';
          }
        }

        if (group3) {
          maskedCpf += group3;
          if (group3.length === 3) {
            maskedCpf += '.';
          }
        }

        if (group4) {
          maskedCpf += group4;
          if (group4.length === 4) {
            maskedCpf += '-';
          }
        }

        if (group5) {
          maskedCpf += group5;
        }

        return maskedCpf;
      },
    );
  }
  return cleanedValue.replace(
    /^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2}).*/,
    (match, group1, group2, group3, group4) => {
      let maskedCpf = '';

      if (group1) {
        maskedCpf += group1;
        if (group1.length === 3) {
          maskedCpf += '.';
        }
      }

      if (group2) {
        maskedCpf += group2;
        if (group2.length === 3) {
          maskedCpf += '.';
        }
      }

      if (group3) {
        maskedCpf += group3;
        if (group3.length === 3) {
          maskedCpf += '-';
        }
      }

      if (group4) {
        maskedCpf += group4;
      }

      return maskedCpf;
    },
  );
};

const numberMask = (value) => value.replace(/\D/g, '');

const alphabetMask = (value) =>
  value.replace(/[^a-zA-Z\u00C0-\u00FF\s]/g, '').replace(/\s{2,}/, ' ');

const emailMask = (value) =>
  value
    .replace(/[^a-zA-Z0-9._+-@]/g, '')
    .toLowerCase()
    .trim();

const maskMobileOnInput = (match, p1, p2, p3) => {
  const part1 = p1 ? `(${p1})` : '';
  const part2 = p2 ? ` ${p2}` : '';
  const part3 = p3 ? `-${p3}` : '';
  return `${part1}${part2}${part3}`;
};

const mobileMask = (value) => {
  let newValue = numberMask(value);
  return newValue.replace(/(\d{2})(\d{0,5})(\d{0,4}).*/, maskMobileOnInput);
};

const onDelete = (e, value) => {
  const pressDelete =
    (e.key === 'Backspace' || e.key === 'Delete') && value.length > 0;
  return pressDelete ? value.slice(0, -1) : value;
};

const currentMonth = new Date().getMonth();
const currentYear = parseInt(String(new Date().getFullYear()).slice(2));
const maxYear = currentYear + 10;

const expireAtMask = (value) => {
  let newValue = numberMask(value);

  if (newValue.length === 2) {
    const month = parseInt(newValue);
    newValue = month > 0 && month < 13 ? newValue : '';
  }

  if (newValue.length === 4) {
    const month = parseInt(newValue.substring(0, 2));
    const year = parseInt(newValue.slice(2));
    newValue =
      month < currentMonth && year === currentYear
        ? ''
        : year >= currentYear && year <= maxYear
        ? newValue
        : newValue.substring(0, 2);
  }
  newValue = newValue.substring(0, 4);
  newValue = newValue.replace(/^(\d{2})(\d{0,2})/, '$1/$2');
  return newValue;
};

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  useGrouping: false,
});

const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  useGrouping: false,
});

const brlMask = (value) => formatter.format(value);

const usdMask = (value) => usdFormatter.format(value);

export {
  brlMask,
  usdMask,
  documentMask,
  alphabetMask,
  emailMask,
  numberMask,
  mobileMask,
  expireAtMask,
  onDelete,
};
