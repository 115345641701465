export const Information = ({ t }) => {
  const paragraphs = t('event.description.paragraphs', { returnObjects: true });
  return (
    <>
      <h2 className='mt-3 mb-3 display-7'>{t('event.description.title')}</h2>
      {paragraphs.map((paragraph, index) => (
        <p
          key={index}
          className='mb-3'
        >
          {paragraph}
        </p>
      ))}
    </>
  );
};
