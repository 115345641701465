import {
  isCVV,
  isCreditCardNumber,
  isEmpty,
  isEmail,
  isCPForCNPJ,
  isMobile,
} from '../helpers/validation';

export const useError = () => {
  const goToFieldWithError = (id) => {
    const element = document.getElementById(id);
    let offset = 150;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
    document.getElementById(id).focus();
  };

  const attendeeError = {
    name: (attendee) => !isEmpty(attendee.name),
    email: (attendee) => isEmail(attendee.email),
    mobile: (attendee) => isMobile(attendee.mobile),
    document: (attendee) => isCPForCNPJ(attendee.document),
  };

  const cctokenError = {
    number: (cctoken) => isCreditCardNumber(cctoken.number),
    name: (cctoken) => !isEmpty(cctoken.name),
    country: (cctoken) => !isEmpty(cctoken.country),
    expireAt: (cctoken) => !isEmpty(cctoken.expireAt),
    code: (cctoken) => isCVV(cctoken.code),
  };

  const customerError = {
    name: (customer) => !isEmpty(customer.name),
    email: (customer) => isEmail(customer.email),
  };
  return {
    attendeeError,
    customerError,
    cctokenError,
    goToFieldWithError,
  };
};
