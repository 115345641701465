import { Row, Col } from 'reactstrap';
import {
  onDelete,
  alphabetMask,
  emailMask,
  mobileMask,
  documentMask,
} from '../../helpers/masks';

export const Attendee = ({
  index,
  attendee,
  fillAttendee,
  length,
  t,
  htmlId,
}) => {
  return (
    <>
      <h3 className='mb-3'>
        Informações do {length === 1 ? '' : `${index + 1}º`} doador
      </h3>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-name`}
          >
            <i className='bi bi-person me-2' />
            {t('attendee.fields.name')}
          </label>
          <input
            id={`attendee-${index}-name`}
            className='form-control'
            autoComplete='off'
            value={attendee.name}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('name', newValue, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-email`}
          >
            <i className='bi bi-envelope me-2'></i>
            {t('attendee.fields.email')}
          </label>
          <input
            type='email'
            id={`attendee-${index}-email`}
            className='form-control'
            name='email'
            autoComplete='off'
            value={attendee.email}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = emailMask(value);
              fillAttendee('email', newValue, index);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-mobile`}
          >
            <i className='bi bi-telephone me-2'></i>
            {t('attendee.fields.mobile')}
          </label>
          <input
            id={`attendee-${index}-mobile`}
            className='form-control'
            autoComplete='off'
            value={attendee.mobile}
            maxLength={15}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = mobileMask(value);
              fillAttendee('mobile', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.mobile);
              fillAttendee('mobile', newValue, index);
            }}
          />
        </Col>
        <Col
          md={6}
          className='mb-3'
        >
          <label
            className='form-label'
            htmlFor={`attendee-${index}-document`}
          >
            <i className='bi bi-building me-2'></i>
            {t('attendee.fields.document')}
          </label>
          <input
            id={`attendee-${index}-document`}
            className='form-control'
            autoComplete='off'
            value={attendee.document}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = documentMask(value);
              fillAttendee('document', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.document);
              fillAttendee('document', newValue, index);
            }}
          />
        </Col>
      </Row>
    </>
  );
};
