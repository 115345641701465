import { useState, useEffect, useContext } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import Header from '../../components/header';
import { getTickets } from '../../services/product';
import { Information } from '../../components/information';
import { useNavigate } from 'react-router-dom';
import { TransactionContext } from '../../contexts/TransactionContext';
import { useTranslation } from 'react-i18next';
import { Tickets } from '../../components/tickets';
import Event from '../../components/event';
import { EVENTID } from '../../helpers/constants';

export const Cart = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const { setCart, firstStep } = useContext(TransactionContext);
  const [tickets, setTickets] = useState([]);

  const updateTicketCount = (value, index) =>
    setTickets((prevState) => {
      const newState = [...prevState];
      const currentCount = newState[index].count;
      const newCount = currentCount + value;
      if (newState[index] === undefined || newCount < 0) {
        return newState;
      }
      newState[index] = { ...newState[index], count: newCount };
      return newState;
    });

  const handleAddTicket = (e, index) => {
    const ticketOnCart = tickets.filter((ticket) => ticket.count > 0);

    updateTicketCount(1, index);
  };

  const handleRemoveTicket = (e, index) => {
    updateTicketCount(-1, index);
  };

  const handleBuy = (e) => {
    e.preventDefault();
    const ticketToBuy = tickets.filter((ticket) => ticket.count > 0);
    if (ticketToBuy.length > 0) {
      setCart(ticketToBuy);
      navigate('/pagamento');
    }
  };

  useEffect(() => {
    const fetchTickets = async () => {
      i18n.changeLanguage('pt');
      document.documentElement.setAttribute('lang', t('language'));
      document.title = t('title');
      const response = await getTickets(EVENTID);
      const newTickets = response.map((ticket) => ({
        ...ticket,
        count: 0,
      }));
      setTickets(newTickets);
      firstStep();
    };
    fetchTickets();
  }, []);

  return (
    <section className='d-flex flex-column justify-content-center align-items-center w-100'>
      <Header />
      <Card>
        <CardBody>
          <Row>
            <Col
              md={6}
              className='d-flex flex-column gap-1'
            >
              <section className='mb-3'>
                <Event t={t} />
                <Information t={t} />
              </section>
            </Col>
            <Col
              md={6}
              className='d-flex flex-column gap-1'
            >
              <Tickets
                handleAdd={handleAddTicket}
                handleRemove={handleRemoveTicket}
                handleBuy={handleBuy}
                tickets={tickets}
                t={t}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </section>
  );
};
